<template>
  <van-overlay :show="show" @click="show = false">
    <van-loading type="spinner" vertical class="loading"/>
  </van-overlay>
  <van-cell-group>
    <van-field v-model="cardNum" label="户号" placeholder="请输入户号" clearable right-icon="question-o" v-on:click-input="$router.push('/accountNumber')" v-on:clickRightIcon="$router.push('/accountNumberDescription')" />
  </van-cell-group>
  <van-cell-group>
    <van-field v-model="city" label="地区" placeholder="请输入地区(例如：北京)" clearable right-icon="search" v-on:clickRightIcon="$router.push('/gasSustain')" />
  </van-cell-group>

  <div class="recharge-list-view">

      <div class="recharge-title" v-if="slowList.length!=0">
        <b>燃气慢充</b>
        <span>72小时内到账,请耐心等待</span>
        <p style="color: red;margin:5px 0 0;padding:0;">欠费超500请勿充值!充值金额要大于欠费金额!</p>
        <div class="recharge-title-btn">
          <nut-row>
            <nut-col :span="8" :key="item.id" v-for="item in slowList" v-on:click="setItem(item)">
              <div :class="{'flex-content':true,active:item.id == obj.id}">
                <span class="recharge-discount">{{100*(userInfo.level?item.vipRate:item.marketRate)}}折</span>
                <b>{{item.amount}}元</b>
                <p>{{userInfo.level?'售':'会员'}}{{(item.amount * item.vipRate).toFixed(2)}}</p>
              </div>
            </nut-col>
          </nut-row>
        </div>
      </div>

    <div class="recharge-title recharge-title-1" v-if="fastList.length!=0">
    <!-- <div class="recharge-title recharge-title-1" v-if="fastList.length!=0 && province!='黑龙江省' && province!='江西省' && province!='山西省'"> -->
      <b>燃气快充</b>
      <span>24小时内到账,请耐心等待</span>
      <p style="color: red;margin:5px 0 0;padding:0;">欠费超500请勿充值!充值金额要大于欠费金额!</p>
      <div class="recharge-title-btn">
        <nut-row>
          <nut-col :span="8" :key="item.id" v-for="item in fastList"  v-on:click="setItem(item)">
            <div :class="{'flex-content':true,active:item.id == obj.id}">
              <span class="recharge-discount">{{100*(userInfo.level?item.vipRate:item.marketRate)}}折</span>
              <b>{{item.amount}}元</b>
              <p>{{userInfo.level?'售':'会员'}}{{(item.amount * item.vipRate).toFixed(2)}}</p>
            </div>
          </nut-col>
        </nut-row>
      </div>
    </div>
    <div class="recharge-title recharge-title-1" v-if="fastList.length==0 && slowList.length==0">
      <b>暂不支持充值类型</b>
    </div>

    <div class="recharge-explain">
      <b>燃气充值说明</b>
      <p>到账时间：正常情况0-24小时内到账，高峰期延迟至72小时内到账（电费可能会以多次如50元/100元等分次到账，总值200元）可叠加充值；</p>
      <p v-on:click="$router.push('/login')" style="color: red;" v-if="!token">登录才能查询到充值记录!</p>
      <p v-on:click="$router.push('/upgradeMember')" style="color: red;" v-if="token&&!userInfo.level">点击这里升级会员!</p>
      <p>家用电和工用电都可以充，下单请准确填写用电户号和所在省份城市</p>
      <p>如因用电户号和所在省份填写错误，由用户自行承担，请务必准确填写用电户号和所在城市；</p>
      <p>充值金额要大于欠费金额(慢充大额到账较慢,还请耐心等待)</p>
      <p>充值成功后请登录（网上国网APP）进行查询；</p>
    </div>
  </div>

  <div class="recharge-btn">
    <nut-row>
      <nut-col :span="16">
        <div class="flex-content payment">
          {{userInfo.level?'尊享':'应付'}} {{money||0}}元
        </div>
      </nut-col>
      <nut-col :span="8" v-on:click="pay()">
        <div class="flex-content flex-content-light flex-content-btn">
         支付
        </div>
      </nut-col>
    </nut-row>
  </div>

</template>
<script>
    import cookie from '../utils/CookieUtil'

    import AMapLoader from '@amap/amap-jsapi-loader';

import storage from '../utils/Storage'

export default {
  name: 'RechargeElctricityBill',
  data(){
    return {
      cardNum:"",
      error:true,
      slowList:[
        {"id":1,"agentId":204,"productCode":"FAKA","productName":"FAKA移动充值","routingCost":0.92,"vipRate":0.95,"amount":50,"marketRate":0.96,"sequence":0,"supportIsp":"移动","isShow":1,"addTime":"2021-08-10 10:23:31","updateTime":"2021-08-20 11:39:45"},
        {"id":2,"agentId":204,"productCode":"FAKA","productName":"FAKA移动充值","routingCost":0.92,"vipRate":0.95,"amount":100,"marketRate":0.96,"sequence":0,"supportIsp":"移动","isShow":1,"addTime":"2021-08-10 10:23:31","updateTime":"2021-08-20 11:39:45"},
        {"id":5,"agentId":204,"productCode":"FAKA","productName":"FAKA移动充值","routingCost":0.92,"vipRate":0.95,"amount":150,"marketRate":0.96,"sequence":0,"supportIsp":"移动","isShow":1,"addTime":"2021-08-20 11:43:40","updateTime":"2021-08-20 11:43:40"},
        {"id":6,"agentId":204,"productCode":"FAKA","productName":"FAKA移动充值","routingCost":0.92,"vipRate":0.95,"amount":200,"marketRate":0.96,"sequence":0,"supportIsp":"移动","isShow":1,"addTime":"2021-08-20 11:44:47","updateTime":"2021-08-20 11:44:47"},
        {"id":7,"agentId":204,"productCode":"FAKA","productName":"FAKA移动充值","routingCost":0.92,"vipRate":0.95,"amount":250,"marketRate":0.96,"sequence":0,"supportIsp":"移动","isShow":1,"addTime":"2021-08-20 11:44:47","updateTime":"2021-08-20 11:44:47"},
        {"id":8,"agentId":204,"productCode":"FAKA","productName":"FAKA移动充值","routingCost":0.92,"vipRate":0.95,"amount":300,"marketRate":0.96,"sequence":0,"supportIsp":"移动","isShow":1,"addTime":"2021-08-20 11:44:47","updateTime":"2021-08-20 11:44:47"}
        ],
      fastList:[
        {"id":10,"agentId":204,"productCode":"FAKA","productName":"FAKA移动充值","routingCost":0.94,"vipRate":0.95,"amount":50,"marketRate":0.96,"sequence":0,"supportIsp":"移动","isShow":1,"addTime":"2021-08-10 10:23:31","updateTime":"2021-08-20 11:39:45"},
        {"id":12,"agentId":204,"productCode":"FAKA","productName":"FAKA移动充值","routingCost":0.94,"vipRate":0.95,"amount":100,"marketRate":0.96,"sequence":0,"supportIsp":"移动","isShow":1,"addTime":"2021-08-10 10:23:31","updateTime":"2021-08-20 11:39:45"},
        {"id":15,"agentId":204,"productCode":"FAKA","productName":"FAKA移动充值","routingCost":0.94,"vipRate":0.95,"amount":150,"marketRate":0.96,"sequence":0,"supportIsp":"移动","isShow":1,"addTime":"2021-08-20 11:43:40","updateTime":"2021-08-20 11:43:40"},
        {"id":16,"agentId":204,"productCode":"FAKA","productName":"FAKA移动充值","routingCost":0.94,"vipRate":0.95,"amount":200,"marketRate":0.96,"sequence":0,"supportIsp":"移动","isShow":1,"addTime":"2021-08-20 11:44:47","updateTime":"2021-08-20 11:44:47"},
        {"id":17,"agentId":204,"productCode":"FAKA","productName":"FAKA移动充值","routingCost":0.94,"vipRate":0.95,"amount":250,"marketRate":0.96,"sequence":0,"supportIsp":"移动","isShow":1,"addTime":"2021-08-20 11:44:47","updateTime":"2021-08-20 11:44:47"},
        {"id":18,"agentId":204,"productCode":"FAKA","productName":"FAKA移动充值","routingCost":0.94,"vipRate":0.95,"amount":300,"marketRate":0.96,"sequence":0,"supportIsp":"移动","isShow":1,"addTime":"2021-08-20 11:44:47","updateTime":"2021-08-20 11:44:47"}
      ],
      money:0.0,
      obj:{},
      isp:"",
      recommendUserId:0,
      token:null,
      city:"",
      province:"",
      show:false,
      type:0,
      userInfo:{}
    }
  },
  beforeCreate () {
    document.querySelector('body').setAttribute('style', 'background:#f5f5f5')
  },
  beforeDestroy () {
    document.querySelector('body').removeAttribute('style')
  },
  created(){
    console.log("------created---");
    //获取登录token
    this.token = cookie.getCookie(cookie.token);
    this.recommendUserId = this.$route.params.recommendUserId||2;
    console.log('recommendUserId',this.recommendUserId);
    let accountNumber = storage.getObjOpsValue("/rechargeGasBill");
    console.log(accountNumber);
    if(accountNumber != null){
      this.city = accountNumber.city;
      this.province = accountNumber.province;
      this.type = accountNumber.type;
      if(accountNumber != null){
        console.log("accountNumber",accountNumber);
        this.city = accountNumber.city;
        this.cardNum = accountNumber.accountNumber;
        if(this.type == 0){
          this.isp = '国家电网';
        }else if(this.type == 1){
          this.isp = '南方电信';
        }else if(this.type == 2){
          this.isp = '中国燃气';
        }else if(this.type == 3){
          this.isp = '华润燃气';
        }else if(this.type == 4){
          this.isp = '港华燃气';
        }
      }
      this.cardNum = accountNumber.accountNumber;
    }
    this.getUserInfo();
  },
  watch:{
    'cardNum'(newValue, oldValue){
          if(newValue!=""){
            let isp = '国家电网';
            if(this.type == 0){
              isp = '国家电网';
            }else if(this.type == 1){
              isp = '南方电信';
            }else if(this.type == 2){
              isp = '中国燃气';
            }else if(this.type == 3){
              isp = '华润燃气';
            }else if(this.type == 4){
              isp = '港华燃气';
            }
            this.productconfig(isp);
          }
      }
  },
  methods:{
      pay(){
        if(this.cardNum==""){
            this.$toast.fail('请输入正确的户号');
            return;
        }
        if(this.money!=((this.obj.amount * (this.userInfo.level?this.obj.vipRate:this.obj.marketRate)).toFixed(2))){
          this.$toast.fail('请求选择充值金额');
          return;
        }
        let pay_type = this.isWeiXin();

        // let url = '/app/wx';
        let url = '/app/';
        if(pay_type){
          url = url + "wx";
        }else{
          url = url + "ali";
        }

         this.$dialog.confirm({
          title: '欠费超500请勿充值!',
          message:
           `当前要充值的户号为 “${this.cardNum}” ,为了避免充值户号,请仔细核对,户号错误充值失败无法退款,确定给这个户号充值吗?`,
        })
          .then(() => {
            this.axios.post(url+'/pay', {
                      isp:this.isp,
                      orderAmt:this.obj.amount,
                      productCode:this.obj.productCode,
                      phoneNumber:this.cardNum,
                      recommendUserId:this.recommendUserId,
                      type:this.type
                    }).then((response) => {
                      if(response!=undefined && response!='' && response!=401 && response!=500 && pay_type){
                          window.location.href = response;
                      }
                      if(response!=undefined && response!='' && response!=401 && response!=500 && !pay_type){
                        document.write(response)
                      }

                        // this.slowList = response;
                    }).catch(function (error) {
                        console.log(error);
                    });
          })
          .catch(() => {
            // on cancel
          });



      },
      setItem(obj){
        this.obj = obj;
        let rate = this.userInfo.level?obj.vipRate:obj.marketRate
        this.money = (obj.amount * rate).toFixed(2);
        this.type= obj.type
      },
      productconfig(isp){
          if(isp == '国家电网' || isp == '南方电信'){
            this.$toast.fail('账户选择类型不正确!');
            this.slowList = [];
            this.fastList = [];
            //制空选择的金额
            this.setItem({amount:0,marketRate:0,vipRate:0});
            return;
          } 
          this.axios.post('/app/productconfig/electricityBillList', {
                  isp
                }).then((response) => {
                    this.slowList = response.slowList;
                    this.fastList = response.fastList;
                    //制空选择的金额
                    this.setItem({amount:0,marketRate:0,vipRate:0});
                }).catch(function (error) {
                    console.log(error);
                });

      },
      getUserInfo(){
        this.axios.post('/app/userInfo', {
            }).then((response) => {
              this.userInfo = response;
              if(this.userInfo.level){
                console.log("等级",response.level);
              }
            }).catch(function (error) {
                console.log(error);
            });
      },
      isWeiXin(){
          //获取浏览器属性
          //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
          let ua = window.navigator.userAgent.toLowerCase();
          //通过正则表达式匹配ua中是否含有MicroMessenger字符串
          if(ua.match(/MicroMessenger/i) == 'micromessenger'){
              return true;
          }
          return false;
      }
  }
}
</script>

<style scoped>
.recharge-list-view{
  min-height: 85vh;
  background: #ffffff;
  margin: 10px 10px 50px 10px;
  border-radius: 20px;
  padding: 15px;
}
.recharge-list-view .recharge-title b{
  font-size: 20px;
}
.recharge-title-1{
  margin-top: 20px;
}
.flex-content{
  position:relative;
}
.recharge-discount{
  position: absolute;
  left: -12px;
  top: -2px;
  padding: 0 8px;
  border-radius: 10px 0 10px 0px;
  background: #2BD67E;
  color: #ffffff !important;
  margin: 2px;
}
.recharge-list-view .recharge-title span{
  font-size: 12px;
  color: #cccccc;
  margin-left: 12px;
}
.recharge-title-btn{
  text-align: center;
}
.recharge-title-btn .flex-content{
  border: 1px solid #cccccc;
  margin: 10px 5px;
  padding: 20px 0 5px 0;
  border-radius: 15px;
}
.recharge-title-btn .flex-content p{
  margin: 5px;
  font-size: 12px;
}
.recharge-explain{
  background: #F0FCF8;
  padding: 10px;
}
.recharge-explain p{
  margin: 3px;
  font-size: 13px;
  color: #929292;
}
.recharge-btn{
  background: #ffffff;
  border-top: 1px solid #cccccc;
  height: 60px;
  line-height: 60px;
  position: fixed;
  bottom: 0;
  width: 100%;
}
.payment{
  margin-left: 20px;
  color: #ff2a2a;
  font-size: 18px;
}
.flex-content-btn{
  background: #2BD67E;
  text-align: center;
  color: #ffffff;
}
.active{
  background: #c9ffd2;
}
.loading{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
</style>
