export default {
    //存放普通k,v
    setStringOpsValue(k, v) {
        localStorage.setItem(k, v);
    },
    //获存放普通k,v
    getStringOpsValue(k) {
        return localStorage.getItem(k);
    },
    //存放json数据
    setObjOpsValue(k, v) {
        v = JSON.stringify(v);
        localStorage.setItem(k, v);
    },
    //获取json数据
    getObjOpsValue(k) {
        try {
            return JSON.parse(localStorage.getItem(k));
        } catch (e) {
            console.log(e);
            return null;
        }
    },
    //删除存储
    deleteByKey(k){
        localStorage.removeItem(k);
    }
}

